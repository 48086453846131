import { render, staticRenderFns } from "./DialogoItensDeImportacao.vue?vue&type=template&id=e31f577e&"
import script from "./DialogoItensDeImportacao.vue?vue&type=script&lang=ts&"
export * from "./DialogoItensDeImportacao.vue?vue&type=script&lang=ts&"
import style0 from "./DialogoItensDeImportacao.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VCardTitle,VDataTable,VDialog,VToolbarTitle,VTooltip})
