

















































































































import { Importacao } from '@/models'
import { nextTick } from '@/shareds/utils'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { Vue, Component, Watch, Ref } from 'vue-property-decorator'
import { DataOptions } from 'vuetify'
import { FindImportacaoUseCase } from '@/usecases'
import axios, { CancelTokenSource } from 'axios'
import { sortConverter } from '@/shareds/paginacao'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import router from '@/router'
import DialogoItensDeImportacao from './DialogoItensDeImportacao.vue'
import RangeDatePicker from '@/components/ui/RangeDatePicker.vue'
import moment from 'moment'

@Component({
	components: {
		DialogoItensDeImportacao,
		RangeDatePicker,
	},
})
export default class TelaDeImportacoes extends Vue {
	importacoes: Importacao[] = []
	@Ref() dialogoImportacao!: DialogoItensDeImportacao

	findUseCase = new FindImportacaoUseCase

	filtro = {
		tipoDeProcesso: [],
		origem: [],
		status: [],
		datas: [],
		horas: [null, null],	
	}
	cancelToken: CancelTokenSource | null = null
	totalDeRegistros = -1
	buscando = false

	headers = [
		{ text: 'Tipo de Processo', value: 'tipoDeProcesso'},
		{ text: 'Processo', value: 'origem' },
		{ text: 'Status', value: 'status' },
		{ text: 'Iniciado em', value: 'createdAt' },
		{ text: 'Quantidade (items)', value: 'totalItens' },
		{ text: 'Ações', value: 'actions', 	sortable: false},
	]

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	get itensFormatados() {
		
		return this.importacoes.map(importacao => ({
			...importacao,
			createdAt: !importacao.createdAt ? '-' : dateTimeToPtBrFormat(importacao.createdAt),
		}))
	}

	statusColor(status: string) {
		if(status === 'Concluído' || status === 'Exportação') return 'success--text font-weight-bold'
		if(status === 'Em Andamento') return 'info--text font-weight-bold'
		if(status === 'Pendente' || status ==='Atualização') return 'warning--text font-weight-bold'
		if(status === 'Incompleto' || status === 'Importação') return 'error--text font-weight-bold'
	}

	returnMessage(origem: string) {
		if(origem == 'Tabela De Preço') return 'Ir para a Tabela De Preço'
		if(origem == 'Estoque') return 'Ir para Tela de Estoque'
		if(origem == 'Cliente') return 'Ir para a Tela de Clientes'
	}

	irPara(origem: string, idOrigem: string) {
		if(origem == 'Tabela De Preço') {
			router.push({
				path: `precos/${idOrigem}`,
			})
		}else if(origem == 'Estoque'){
			router.push({
				path: `estoques?l=${idOrigem}`,
			})
		} else if(origem == 'Cliente') {
			router.push({
				path: `/clientes`,
			})
		}
	}

	get idLoja() {
		return this.$route.query.l as string
	}

	gerarDataHora(data?: string | null, hora?: string | null): string | undefined {
		return moment(`${data} ${hora}`,  'YYYY-MM-DD HH:mm').toISOString(true)
	}

	async buscar() {
		if (this.cancelToken) this.cancelToken.cancel()
		await nextTick()
		try {
			this.buscando = true
			this.cancelToken = axios.CancelToken.source()
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}
			const sorted = sortConverter(this.paginacao)

			if(this.isReverseData()) {
				this.filtro.datas.reverse()
			}
			const dataHoraInicial = this.gerarDataHora(this.filtro.datas[0], this.filtro.horas[0] || '00:00')
			const dataHoraFinal = this.gerarDataHora(this.filtro.datas[1], this.filtro.horas[1] || '23:59')

			const filtrosFormatados = {
				tipoDeProcesso: this.filtro.tipoDeProcesso,
				origem: this.filtro.origem,
				status: this.filtro.status,
				dataInicial: dataHoraInicial ? dataHoraInicial : undefined,
				dataFinal: dataHoraFinal ? dataHoraFinal : undefined,
			}
			
			const page = await this.findUseCase.find(
				{
					...filtrosFormatados,
					sort: !sorted.length ? "createdAt,DESC" : [...sorted],
					page: this.paginacao.page - 1 || 0,
					size: this.paginacao.itemsPerPage,
				},
				axiosConfig)
			this.importacoes = page.content
			this.totalDeRegistros = page.totalElements

		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.buscando = false
		}
	}

	isReverseData() {
		if(!this.filtro.datas[0] || !this.filtro.datas[1]) return false
		if(this.filtro.datas[1] < this.filtro.datas[0]) return true
	}

	@Watch('paginacao', { deep: true })
	onChangePaginacao = this.buscar

}
